#kickoffRoute--form {
  background-color: rgb(253, 253, 249);
  grid-auto-rows: 2rem;
  row-gap: 2.5rem;
}

#kickoffRoute--form .form-row,
.form-label {
  margin-bottom: 1rem;
}

#kickoffRoute--form input {
  width: 100%;
}
