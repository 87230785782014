:root {
  /* Color variants */
  --primary: #264653;
  --primary-fore: #fff;
  --primary--light: #4d94b1;
  --primary--light-fore: #fff;
  --secondary: #4d94b1;
  --secondary-fore: #fff;
  --warning: #e9c46a;
  --warning-fore: #222;
  --dark: #0d1416;
  --placeholder: #787272;
  --face: #fff;
  --face-fore: #666;
  --face-fore-dark: #333;
  --selected: #dadada;
  --selected-fore: #555;
  --danger: #f53b00;
  --danger-fore: #fff;
  --notes: #fffcbd;
  --notes-fore: #000;
  --success: #7ed061;
  --success-fore: #fff;
  --disabled: rgb(233, 233, 233);
  --disabled-fore: #999;
  --muted-for: #787272;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 10px;
}
body {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

/*
    Elements 
*/
h1,
h2,
h3 {
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.5rem;
}

h3 {
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
  line-height: 4rem;
}

a {
  text-decoration: none;
  padding: 1rem 0;
}

a:visited {
  color: white;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.italic {
  font-style: italic;
  color: gray;
}

/* 
grids 
*/
.grid {
  display: grid;
}

.col1 {
  grid-column: 1;
}
.col2 {
  grid-column: 2;
}
.span-col2 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.gap-small {
  column-gap: 1rem;
}
/* 
 Styles
*/
.primary {
  background-color: var(--primary);
  color: var(--primary-fore);
}

.color-excedente {
  background-color: #315225;
  color: #fff;
}

.secondary {
  background-color: var(--secondary);
  color: var(--secondary-fore);
}

.warning {
  background-color: var(--warning);
  color: var(--warning-fore);
}

.warning-fore {
  color: var(--warning);
}
.success {
  background-color: var(--success);
  color: var(--success-fore);
}

.success-fore {
  color: var(--success);
}

.danger {
  background-color: var(--danger);
  color: var(--danger-fore);
}
.danger-fore {
  color: var(--danger);
}

.dark {
  background-color: var(--dark);
  color: var(--dark-fore);
}

.selected {
  background-color: var(--selected);
  color: var(--selected-fore);
}

.positivo {
  color: #7aad60;
}

.negativo {
  color: rosybrown;
}

/*
 Form
*/
.form {
  padding: 1rem;
  font-size: 2rem;
  line-height: 1.7rem;
}

.form label {
  margin-bottom: 0.8rem;
}

.form input,
textarea {
  border: 1.5px solid var(--primary);
  border-radius: 5px;
  padding: 0.5rem;
  background-color: transparent;
  color: var(--dark);

  outline: none;
  transition: background-color 200ms ease;
  font-size: 2rem;
  -webkit-appearance: none;
}
.form input {
  height: 4rem;
}

.form.primary input {
  border: 1.5px solid var(--primary-fore);
  background-color: var(--secondary);
  color: var(--primary-fore);
}

.form input:focus,
textarea:focus {
  border-color: var(--warning);
  border-width: 2px;
}

.form--error {
  margin: 2rem 0;
  color: var(--warning);
}

.form--error-icon {
  padding-right: 1rem;
}

.form--vertical {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.form--vertical input {
  margin-bottom: 0.8rem;
}
.form--vertical label {
  margin-bottom: 0;
}

.form--vertical .select {
  margin-top: 1rem;
}

.field-group {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.div-left {
  display: flex;
  justify-content: flex-start;
}

.div-right {
  display: flex;
  justify-content: flex-end;
}

.div-center {
  display: flex;
  justify-content: center;
}

.div-between {
  display: flex;
  justify-content: space-between;
}

.div-center-vertical {
  display: flex;
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

/* 
    Buttons
*/
.btn {
  min-width: 10rem;
  height: 4rem;
  padding: 0rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.8rem;
  transform: background-color 200ms linear;
}

.btn.rounded {
  min-width: 0;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 50px;
}

.btn:active {
  transform: scale(0.98, 0.98);
}

.btn.outline {
  border: 2px solid var(--primary-fore);
  border-radius: 10px;
}
.btn--primary:hover {
  background-color: var(--dark);
}

.btn:disabled {
  background-color: silver;
}

.btn.large {
  padding: 2rem 5rem;
}
.btn.small {
  height: auto;
  font-size: 1.5rem;
  min-width: 1rem;
}

.btn.icon {
  padding: 1rem;
  min-height: 2rem;
  min-width: 2rem;
}

.full-width {
  width: 100%;
}
/*
 Auxiliares
 */
.flip-vertical {
  transform: rotate(180deg);
  margin-top: 20px;
  margin-bottom: -3px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  opacity: 0.95;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.box {
  border: 1px solid #ddd;
  box-shadow: 0 0 1px #e3e8ee;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
}

/*
 bar 
*/

.bar {
  justify-content: flex-start;
}

.bar--double {
  display: grid;
  grid-template-columns: 4rem 1fr auto;
}

.bar.single {
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  font-size: 1.5rem;
}

/* 
 Spinners
 */
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: spinner-animation 1s infinite linear;
}

.spinner.warning {
  background: radial-gradient(farthest-side, var(--warning) 94%, #0000) top/8px
      8px no-repeat,
    conic-gradient(#0000 30%, var(--warning));
}
.spinner.primary {
  background: radial-gradient(farthest-side, var(--primary) 94%, #0000) top/8px
      8px no-repeat,
    conic-gradient(#0000 30%, var(--primary));
}

@keyframes spinner-animation {
  100% {
    transform: rotate(1turn);
  }
}

/* 
Pages
*/
.page-header {
  background-color: var(--primary);
  color: var(--primary-fore);
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.page {
  display: flex;
  flex-direction: column;
}

.page-content {
  padding: 1rem;
  padding-bottom: 6rem;
}

.page-footer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--primary);
  color: var(--primary-fore);
  height: 6rem;
  padding: 1rem 1rem;

  /*  position: absolute;
  bottom: 0;
  width: 100%;*/
}

.page-list {
  margin-top: 2rem;
  overflow: scroll;
}

.list-item {
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  border-bottom: 1px solid #cdcdcd;
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.list-item--heading {
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--face-fore-dark);
}

.list-item--focusable {
  cursor: pointer;
}

.list-item--content {
  color: var(--face-fore);
}

.list-item--content-bold {
  font-weight: 600;
}

.list-item--notes {
  margin: 1rem 0;
  padding: 0.8rem 1rem;
  background-color: var(--notes);
  color: var(--notes-fore);
  border-radius: 5px;
  font-size: 1.2rem;
}

.list-header {
  font-weight: 600;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid #aaa;
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.list-row {
  margin: 0.5rem 0;
  border-bottom: 1px dotted #ccc;
}

.list-header--sortable {
  margin-right: 0.5rem;
  color: #ccc;
}

.list-label {
  font-size: 1.3rem;
}

/*
Search
*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.searchBox {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid var(--dark);
  border-radius: 5px;
  font-size: 1.8rem;
}

.searchBox input {
  border: none;
  outline: none;
  flex: 1;
  padding: 0 0.8rem;
  font-size: 1.8rem;
}
.search--clear-button {
  cursor: pointer;
}

/* 
 Pill
*/
.pill {
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 1.6rem;
  border-radius: 50px;
  flex-direction: column;
}

.pill.large {
  font-size: 2rem;
  padding: 0.5rem 0;
}

/* 
 pill options
 */
.pill-options {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  font-size: 1.6rem;
}

.pill-options.primary,
.pill--option.primary {
  background-color: var(--dark);
  color: var(--danger-fore);
}

.pill--option {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  height: 100%;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
}
.pill--option.selected.primary {
  background-color: var(--primary--light);
  color: var(--primary--light-fore);
}

/* 
 qty-entry
 */
.qty-entry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.qty-entry input {
  border: none;
  outline: none;
  width: 8rem; /* Make it flexible */
  font-size: 1.8rem;
  text-align: center;
  border: 1px solid var(--primary);
  border-radius: 5px;
  margin: 0 0.5rem;
  height: 4rem;
}

.qty-button {
  border-radius: 100px;
  padding: 0.5rem;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.qty-button.disabled {
  background-color: var(--disabled);
  color: var(--disabled-fore);
  cursor: default;
}

/*
  autocomplte
*/
.autocomplete--entry {
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 5px;
  /*margin: 0 0.5rem;*/
  height: 4rem;
  display: flex;
  align-items: center;
}
.autocomplete input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 1.8rem;
  text-align: left;
  margin: 1rem 0.5rem;
}

.autocomplete--entry-clear {
  font-size: 2rem;
  text-align: center;
  width: 2rem;
  margin: 0 1rem;
}
.autocomplete--popup {
  border: 1px solid var(--primary);
  border-radius: 5px;
  margin: 0.5rem;
  width: 100%;
  font-size: 1.4rem;
  line-height: 3rem;
  padding: 0.5rem;
  max-height: 20rem;
  min-height: 2.5rem;
  overflow: scroll;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
}

/*
 checkbox
 */
.checkbox {
  display: flex;
  align-items: center;
}

.checkbox--btn {
  border-radius: 100px;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 3rem;
}

.checkbox--label {
  margin-left: 1rem;
}

/* 
 DateRangeLink
 */
#dateRangeLink--calendar {
  width: fit-content;
}

#dateRangeLink .link {
  width: 100%;
  min-padding: 1rem;
  font-size: 1.5rem;
  text-align: left;
}

#dateRangeLink .link:visited,
#dateRangeLink .link,
#dateRangeLink .link:active {
  color: var(--primary-fore);
  cursor: pointer;
}
#dateRangeLink--calendar .footer {
  background-color: white;

  display: flex;
  padding: 1rem;
  justify-content: flex-end;
}

/*
 Card 
*/
.card {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

/*
 Fonts
 */
.font-large {
  font-size: 4rem;
}
.font-medium {
  font-size: 3rem;
}

.font-small {
  font-size: 2rem;
}

.font-smaller {
  font-size: 1.5rem;
}

.font-muted {
  color: var(--muted-for);
}

.font-bold {
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-1 {
  margin: 1rem;
}

/* 
Modal
*/
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal--content {
  background-color: #fefefe;
  margin: 3rem auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 98%; /* Could be more or less, depending on screen size */
}

.modal--header {
  height: 4rem;
  font-size: 2rem;
  background-color: var(--primary);
  color: var(--primary-fore);
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  align-items: center;
}

.modal--body {
  padding: 0 1rem;
}

.padding-1 {
  padding: 1rem;
}

.modal--footer {
  text-align: right;
  padding: 1rem;
  border-top: 1px solid var(--primary);
}

/*
Floating button
*/
.floating-button {
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  font-size: 18px; /* Increase font size */
  height: 4.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.floating-button--left {
  left: 2rem; /* Place the button 30px from the right */
}

.floating-button--center {
  left: 6rem;
}

.round-button {
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  font-size: 18px; /* Increase font size */
  height: 4.5rem;
  border-radius: 50%;
}

.round-button .shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.paginate-buttons {
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  z-index: 10; /* Make sure it does not overlap */
  width: 100%;
  margin-left: 1rem;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginate-buttons button {
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  height: 4rem;
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 18px; /* Increase font size */
  border-radius: 50%;
  width: 4rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.paginate-buttons button:disabled {
  visibility: hidden;
}
.paginate-buttons button.paginate-buttons--current {
  width: 5rem;
  height: 5rem;
  font-size: 12px;
  overflow-wrap: break-word;
}

.tabs {
  display: flex;
  flex-direction: row;
  margin: 1rem;
}
.tabs--tab {
  font-size: 2rem;
  padding: 1rem;
  color: var(--dark);
}

.tabs--tab.selected {
  background-color: inherit;
  border-bottom: var(--primary) 3px solid;
}

.ptr {
  height: 100vh;
}

.border-bottom {
  padding: 5px;
  border-bottom: 1px solid #888;
}

.collapsiblePanel--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsiblePanel--titlebar {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  margin: 1.8rem 0;
}

.collapsiblePanel--button {
  margin-left: 1rem;
}

.collapsiblePanel--children {
  margin: 0.5rem 1rem;
}

.edoResultados--content {
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  flex-grow: 1;

  margin-right: 4rem;
  border-bottom: 1px solid #0001;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.edoResultados--content--monto {
  justify-self: end;
  margin-right: 1rem;
}

.edoResultados--content--porcentaje {
  justify-self: end;
}

.edoResultados--title {
  font-size: 1.5rem;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: 1.5rem 0;
  padding: 1rem 0;
}
.edoResultados--title-value {
  margin-right: 5rem;
}

.top-border-light {
  border-top: 1px solid #0001;
}
.bottom-border-light {
  border-bottom: 1px solid #0001;
}
