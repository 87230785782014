#cxc .page-list {
  margin: 1rem;
}

#cxc {
  font-size: 1.5rem;
}

#cxcPayment-modal {
}
