/*
NavBar
*/

#navbar {
  align-items: center;
  justify-content: flex-start;
  height: 5rem;
}

#navbar-title {
  font-size: 2.2rem;
  font-weight: 400;
  flex-grow: 1;
}

#navbar-title .col-2 {
  display: flex;
  justify-content: space-between;
}
/* 
NavButton
*/
#navMenu {
  margin: 0 1rem;
  display: inline-block;
  background-color: var(--primary);
}

#navMenu a {
  color: var(--light);
}

#navMenu a:hover {
  color: var(--warning);
}

#nav--hamburger {
  font-size: 2.4rem;
}
/*
Menu
*/
#mainMenu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
}

#menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 85vw;
  /*height: calc(100vh - calc(100vh - 100%));*/
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: var(--face);
  color: var(--face-fore);

  font-size: 1.8rem;

  z-index: 990;
}

#menu-header {
  /*background-image: url(../img/menu-bg.jpeg);*/
  background-color: #7aad60;
  background-position: center;
  height: 12rem;
  color: var(--light);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}

#menu-header-user {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
}

#menu-header-avatar {
  width: 40px;
  height: 40px;
  background-color: var(--warning);
  font-size: 2rem;
  font-weight: 700;
  color: var(--warning-fore);
  border-radius: 50%;
  border: 3px solid white;

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-left: 0.5rem;
}

#menu-header-username {
  margin-left: 0.5rem;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 2rem;
  color: #fff;
}

#menu-header-logout {
  font-size: 2.4rem;
  color: #fff;
}

#menu-header-divider {
  background-color: #7aad60;
  margin: 0;
  margin-top: -1px;
  height: 35px;
}

#menu ul {
  padding: 0;
  margin: 0;
}

#menu li {
  list-style-type: none;
}

#menu a {
  width: 90%;
  height: 100%;
  display: block;
  padding: 1rem;
  color: var(--dark);
}

#menu a:hover {
  border: 1px solid var(--primary);
}

#menu-options {
  overflow: scroll;
}

#menu .menu-item {
  padding: 2rem;
  display: flex;
  cursor: pointer;
}

#menu .menu-item:hover {
  color: var(--selected-fore);
  background-color: var(--selected);
}

.menu-item-icon {
  margin-right: 1rem;
}

.menu-item.selected {
  background-color: var(--selected);
  color: var(--selected-fore);
  font-weight: 600;
}

.menu-item.bottom {
  position: absolute;
  bottom: 0;
}
.nav-button-back {
  font-size: 2.4rem;
  text-align: center;
}

.submenu {
  display: block !important;
}

.submenu--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submenu--body {
  margin-left: 1rem;
  padding: 1rem 0;
}
