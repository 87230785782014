#inhabiles .list-item,
.list-header {
  display: grid;
  grid-template-columns: 8rem 8rem auto;
  column-gap: 1rem;
}

#edit-inhabil .mensaje {
  width: 100%;
}
