#proveedores .disabled {
  opacity: 0.5;
  background-color: #eee;
}
.proveedor--pill-deshabilitado {
  margin: 1rem 0;
  width: 50%;
}

.proveedores--filtro-status {
  margin-top: 1rem;
  width: 28rem;
}

#edit-proveedor .form {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
}

#edit-proveedor .form input,
textarea,
.select,
.autocomplete {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
#edit-proveedor h3 {
  padding-top: 1rem;
}

#edit-proveedor .checkbox {
  margin-bottom: 1rem;
}
