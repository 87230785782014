#purchase-receipt--toolbar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#purchase-receipt--toolbar .btn {
  height: 3rem;
}

#purchase-receipt .pill-options {
  width: fit-content;
  height: 3rem;
  font-size: 1.5rem;
  margin-top: 1rem;
}

#purchase-receipt .list-item {
  display: grid;
  grid-template-columns: 100px auto;
  font-size: 1rem;
  grid-column-gap: 0.5rem;
}

.purchaseReceipt--item {
  background-color: white;
}

#purchase-receipt--item-grid {
  font-size: 1.3rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 2fr);
  grid-row-gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.2rem;
}

#purchase-receipt .cantidad,
.unidad {
  font-size: 2.2rem;
}

#purchase-receipt--showHideSuppliers {
  margin-left: 1rem;
}
#purchase-receipt-supplier {
  background-color: white;
  color: var(--primary);
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  animation: name duration timing-function delay iteration-count direction
    fill-mode;
}

#purchase-receipt-supplier .grid {
  height: 2rem;
}

#purchase-receipt-supplier__title {
  height: 2.5rem;
  background-color: var(--primary);
  color: var(--primary-fore);
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

#purchase-receipt-supplier__title span {
  margin-left: 1rem;
}

#purchase-receipt .autocomplete {
  margin-bottom: 1rem;
}
