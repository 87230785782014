select {
  /* A reset of styles, including removing the default dropdown arrow*/
  appearance: none;
  /* Additional resets for further consistency*/
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
.select {
  width: 100%;
  min-width: 15rem;
  border: 1px solid var(--dark);
  border-radius: 5px;
  padding: 0.8rem;
  font-size: 1.8rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: var(--primary);
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
}

.select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--dark);
  clip-path: polygon(76% 0, 100% 0, 49% 100%, 0 0, 26% 0, 49% 53%);
  display: grid;
  justify-self: end;
}
select,
.select:after {
  grid-area: select;
}

select:focus + .select--focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 3px solid var(--warning);
  border-radius: inherit;
}
