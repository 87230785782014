#gastos .page-list {
  margin: 1rem;
}

#gastos {
  font-size: 1.5rem;
}

#gasto--monto-fecha {
  flex-direction: column;
  align-items: flex-end;
}
