#fechaEntrega-bar {
  font-size: 1.5rem;
}

#sales-dashboard {
  padding-bottom: 5rem;
}

#sales-dashboard--totalPedidos .grid {
  grid-template-columns: 1fr 1fr;
}
#sales-dashboard--totalPedidos .col1,
#sales-dashboard--totalPedidos .col2 {
  width: 100%;
}

#sales-dashboard--totalPedidos .col1 {
  margin: 2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sales-dashboard--totalPedidos #noPedidos {
  margin-bottom: 2rem;
}
#sales-dashboard--totalPedidos #noPedidos :first-child {
  font-size: 8rem;
  font-weight: 600;
  line-height: 7rem;
}
#sales-dashboard--totalPedidos #noPedidos :nth-child(2) {
  font-size: 2rem;
  font-weight: 600;
}

/* TODO: Oculto temporalmente */
#noPedidosDiferencia {
  visibility: hidden;
}

#sales-dashboard--totalPedidos #noPedidosDiferencia {
  font-size: 2.2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

#sales-dashboard--totalPedidos #noPedidosDiferencia :first-child {
  margin-left: 1rem;
}

#sales-dashboard--totalPedidos .col2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label-data {
  margin-bottom: 1rem;
}

.label-data :first-child {
  font-size: 1.8rem;
}
.label-data :nth-child(2) {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.label-data.border-bottom {
  border-bottom: 1px solid #ccc;
}

#sales-dashboard--clientes-nuevos-recurrentes {
  display: flex;
  justify-content: center;
}

#sales-dashboard--clientes-nuevos-recurrentes .grid {
  grid-template-columns: repeat(3, 0.5fr);
  column-gap: 2rem;
  width: 100%;
}

#sales-dashboard--clientes-nuevos-recurrentes > * {
  text-align: center;
}

#sales-dashboard h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
  text-align: left;
}

#sales-dashboard--categoria .grid {
  overflow: scroll;
  font-size: 1.6rem;
  grid-template-columns: 5rem 15rem repeat(3, 10rem);
}
#sales-dashboard--categoria .grid div {
}
.list-header {
  min-height: 2.5rem;
}
.list-row {
  min-height: 2.5rem;
}

/*
  ordersDashboard
*/
#ordersDashboard--orders-list .grid {
  overflow: scroll;
  font-size: 1.6rem;
  grid-template-columns: 18rem 8rem repeat(6, 10rem);
}

#ordersDashboard--orders-list .searchBox {
  margin-bottom: 2rem;
}
