.orders-fulfillment-list-item-footer {
  margin-top: 1rem;
  display: flex;
}

.orders-fulfillment-list-item-footer .pill {
  flex-direction: row;
}

.pill-inprocess {
  background-color: #fc9f81;
}

#selectRuta {
  grid-column-start: 2;
  margin-right: 1rem;
}

/* 
 Order-fulfillment
*/
#orderFulfillment {
  position: relative;
}
#orderFulfillment-header,
#orderFulfillment-notes,
#orderFulfillment-filter {
  grid-column-start: 2;
  height: 100%;
}

#orderFulfillment-header .navbar-title {
  font-size: 1.5rem;
}

#orderFulfillment-header {
  margin-bottom: 1rem;
}

#orderFulfillment-notes {
  font-size: 1.3rem;
  font-weight: 300;
  background-color: var(--notes);
  color: var(--notes-fore);
  padding: 1rem;
  height: 90%;
  overflow: scroll;
  border-radius: 10px;
}

#orderFulfillment-toolbar {
  padding-left: 1rem;
}

#orderFulfillment-filter {
  padding-top: 10px;
  display: flex;
  height: 5rem;
  justify-content: space-between;
}

#order-fulfillment .list-item {
  display: grid;
  font-size: 1rem;
  grid-column-gap: 0.5rem;
}

#order-fulfillment--items .notes {
  font-size: 1.2rem;
  background-color: var(--notes);
  color: var(--notes-fore);
  padding: 1rem;
}

#order-fulfillment--item-grid {
  font-size: 1.3rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 2fr);
  grid-row-gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}
#order-fulfillment--items .list-item {
  padding: 1rem 0;
}
#order-fulfillment--items .cantidad {
  /*font-size: 1.5rem;*/
  font-weight: 600;
}
#order-fulfillment--items .unidad {
  /*font-size: 1.5rem;*/
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 0.5rem;
}

#orders-fulfillment-by-product--items {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 100px auto;
  margin-bottom: 1rem;
}

#orders-fulfillment-by-product--cantidades {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

#orders-fulfillment--refresh button {
  min-width: 5px;
  margin-top: 1rem;
}
