#products .list-item {
  grid-template-columns: 1fr 2fr;
}
#products .list-item--sub-heading {
  font-size: 1.4rem;
  color: #555;
}

.products--image {
  display: flex;
  flex-direction: column;
}
.products--image .pill {
  margin-top: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
}
#products .disabled {
  opacity: 0.5;
  background-color: #eee;
}

.products--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.products--precio {
  margin-top: 1rem;
}

#edit-product .form {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
}

#edit-product .form input,
textarea,
.select,
.autocomplete {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.edit-product--image-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-product--image-container img {
  max-width: 100%;
  max-height: 300px;
}
.edit-product--image-container button {
  margin: 1rem 0;
  width: 100%;
}

#edit-product input[type="file"] {
  display: none;
}

#edit-product .intervalo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  overflow: hidden;
}

#edit-product .intervalo div {
  display: flex;
  flex-direction: column;
}

#edit-product .intervalo input {
  width: 100%;
}

#edit-product .products--precio {
  font-weight: 600;
  font-size: 1.8rem;
}

#stocks .list-item {
  grid-template-columns: 1fr 2fr;
}

.products--filtro-status {
  margin-top: 1rem;
}
