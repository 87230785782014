#login {
  background-color: var(--primary);
  color: var(--primary-fore);
}

#login .form {
  margin-top: 4.5rem;
}

@media screen and (min-width: 400px) {
  #login {
    margin: auto;
    width: 400px;
  }
}

#login a,
#login a:visited {
  color: var(--primary-fore);
}

#login-button {
  margin-top: 3rem;
}

#login-footer {
  background-color: var(--face);
  color: var(--face-fore);
  position: fixed;
  bottom: 0;
  font-size: 1.2rem;
  padding: 1rem;
  font-weight: 400;
}
