#edit-user-profile .form {
  display: flex;
  flex-direction: column;
}

.tab-content {
  margin: 1rem;
}

.edit-profile--tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
}

.edit-profile--tabs-container .floating-add-button {
  position: relative;
}

.user-disabled {
  color: var(--disabled-fore);
}
